<template>
  <div>
    <v-menu :close-on-click="true" :close-on-content-click="true">
      <template #activator="{ on, attrs }">
        <v-btn
          :id="`reservations-list-button-actions`"
          class="btn-secondaryaction"
          v-bind="attrs"
          v-on="on"
        >
          Actions
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <template v-for="option in multiselectActions">
          <v-list-tile
            :id="option.id"
            :key="option.id"
            :class="isDisabled ? 'disabled' : ''"
            @click="handleMultiselectClick(option)"
          >
            <v-list-tile-title>
              {{ option.text }}
            </v-list-tile-title>
          </v-list-tile>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {
  getAvailableDriversForRes,
  getAvailableVehiclesForRes,
  getTripAssignmentsForReservation,
  validateMassAssignmentEligibility,
} from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'
import { authComputed } from '@/state/helpers'
import admin from '@/services/admin'
import drivers from '@/services/drivers'
import { getRoutesForReservations } from '@/services/reservations'
import { getReservationItineraryAsString } from '@/utils/reservation'
import { getDriverPhoneNumber } from '@/utils/tripAssignments'

export default {
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      sidebarDialog: false,
      dialogMode: null,
      multiselectActions: [],
      selectedRowsModel: [],
      model: [],
      drivers: [],
      vehicles: [],
      vehicleTypes: [],
    }
  },
  computed: {
    ...authComputed,
    isDisabled() {
      return this.selectedRows.length === 0
    },
    canCreateShuttleQuotes() {
      return this.$store.getters['auth/hasPermission']('canCreateShuttleQuotes')
    },
  },
  async mounted() {
    this.multiselectActions = [
      {
        id: 'reservations-offer-referrals',
        action: this.offerReferrals,
        text: 'Offer Referrals',
        mode: 'offer-referrals',
      },
      {
        id: 'reservations-accept-referrals',
        action: this.acceptReferrals,
        text: 'Accept Referrals',
        mode: 'accept-referrals',
      },
      {
        id: 'reservations-reject-referrals',
        action: this.rejectReferrals,
        text: 'Reject Referrals',
        mode: 'reject-referrals',
      },
      {
        id: 'reservations-assign-drivers-vehicles',
        action: this.sidebarAssignment,
        text: 'Assign Driver/Vehicle',
        mode: 'assign-drivers-vehicles',
      },
      {
        id: 'reservations-assign-routes-drivers-vehicles',
        action: () =>this.sidebarAssignment(true),
        text: 'Assign Routes Driver/Vehicle',
        mode: 'assign-drivers-vehicles',
      },
      {
        id: 'reservations-copy-itineraries',
        action: this.copyItineraries,
        text: 'Copy Itineraries',
        mode: 'copy-itineraries',
      },
      {
        id: 'reservations-set-manual-referral-needed',
        action: () => this.setManualReferralNeeded(true),
        text: 'Remove from MARGE',
        mode: 'set-manual-referral-needed',
      },
      {
        id: 'reservations-disable-manual-referral-needed',
        action: () => this.setManualReferralNeeded(false),
        text: 'Restart MARGE',
        mode: 'disable-manual-referral-needed',
      },
      {
        id: 'reservations-mark-as-enterprise',
        action: () => this.markAsEnterprise(true),
        text: 'Set as Enterprise',
        mode: 'mark-as-enterprise',
      },
      {
        id: 'reservations-mark-as-not-enterprise',
        action: () => this.markAsEnterprise(false),
        text: 'Set Not Enterprise',
        mode: 'mark-as-not-enterprise',
      },
      {
        id: 'reservations-update-invoice',
        action: this.updateInvoiceData,
        text: 'Update Invoice',
        mode: 'update-invoice',
      }
    ]
    if (this.canCreateShuttleQuotes) {
      this.multiselectActions.push({
        id: 'reservations-offer-route-referrals',
        action: this.offerRouteReferrals,
        text: 'Offer Route Referrals',
        mode: 'offer-route-referrals',
      })
    }

    this.$store
      .dispatch('types/getVehicleTypes')
      .then((data) => {
        this.vehicleTypes = data.data.resultList
      })
      .catch((e) => {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: e.response?.data?.message || e.message,
        })
      })
  },
  methods: {
    getRoutesForReservations,
    getDriverPhoneNumber,
    offerAllReferrals() {},
    offerReferrals() {},
    offerRouteReferrals() {},
    async sidebarAssignment(routeTrip = false) {
      const payload = []
      this.selectedRows.map((row) => {
        payload.push({
          quoteId: row.item.quoteId,
          referralStatus: row.item.referralStatus,
          reservationId: Number(row.item.reservationId),
          referredTo: row.item.referredTo,
        })
      })


      let response = await validateMassAssignmentEligibility(payload)
      try {
        let tripVehicleGroupResponse
        if (routeTrip) {
          const ids = this.selectedRows.map(
              (reservation) => reservation.item.reservationId
            )
          tripVehicleGroupResponse = await this.getRoutesForReservations(ids, true)
        }

        switch (response.data.errorReason) {
          case null:
            this.openAssignmentsSidebar(response, tripVehicleGroupResponse?.data?.tripVehicleGroups)
            break
          case 'invalid_input':
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Please Select Valid reservations to continue',
            })
            break
          case 'multiple_error':
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'All selected reservations must be under a single quote',
            })
            break
          case 'referrals_not_accepted':
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message:
                'Selected reservations include not accepted or not referred reservations',
            })
            break
          case 'driver_vehicle_mismatch':
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Driver/Vehicle mismatch',
            })
            break
          case 'provider_mismatch':
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Provider Mismatch',
            })
            break
          default:
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Unknown Error Occurred',
            })
            break
        }
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: error.response?.data?.message || 'There was an error loading the reservations. Please try again.',
        })
      }
    },
    openAssignmentsSidebar(response, tripVehicleGroupResponse = null) {
      const component = () =>
        import('./MultiAssignDispatchReservationSidebar.vue')
      let childProps = []
      let quoteId = response.data.data.quoteId
      let responseCore = response.data.data.massAssignmentOutlineList
      let existingAssignments = new Map()
      response.data.data.existingAssignments.forEach((x) => {
        if (!existingAssignments.has(x.companyId)) {
          existingAssignments.set(x.companyId, new Map())
        }
        if (!existingAssignments.get(x.companyId).has(x.vehicleTypeId)) {
          existingAssignments.get(x.companyId).set(x.vehicleTypeId, new Map())
        }
        if (!existingAssignments.get(x.companyId).get(x.vehicleTypeId).has(x.tripVehicleGroupId)) {
          existingAssignments.get(x.companyId).get(x.vehicleTypeId).set(x.tripVehicleGroupId, [])
        }
        const driverIds = []
        for (let driverAssignment of x.driverAssignments) {
          driverIds.push(driverAssignment.userId)
        }
        existingAssignments.get(x.companyId).get(x.vehicleTypeId).get(x.tripVehicleGroupId).push({
          vehicleId: x.vehicleId,
          driverIds: driverIds,
        })
      })
      let model = []
      // If it is a route trip, build the model based off of tri vehicle groups,
      // if not push the model into the vehicle assignments list of the first object
      if (tripVehicleGroupResponse) {
        let referralTripVehicleGroupIds = tripVehicleGroupResponse?.map(rv => rv.parentTripVehicleGroupId || rv.tripVehicleGroupId)
        for (const tvg of tripVehicleGroupResponse) {
          if (referralTripVehicleGroupIds?.includes(tvg.tripVehicleGroupId)) {
            model.push(
              {
                tripVehicleGroupId: tvg.tripVehicleGroupId,
                name: tvg.name,
                vehicleAssignments: [],
              },
            )
          }
        }
      } else {
        model.push({
          tripVehicleGroupId: null,
          name: '',
          vehicleAssignments: [],
        })
      }
      for (var i = 0; i < responseCore.length; i++) {
        let responseFaction = responseCore[i]
        let companyId = responseFaction.companyId
        let requiredDrivers = responseFaction.requiredDrivers
        let reservationIds = responseFaction.reservationIds
        let parentReservationIds = responseFaction.parentReservationIds
        var driversToAdd = requiredDrivers
        let companyName = responseFaction.companyName

        let rvb = responseFaction.referralVehicles
        for (var j = 0; j < rvb.length; j++) {
          let rv = rvb[j]
          let vehicleTypeId = rv.vehicleType.id
          let vehicleTypeLabel = rv.vehicleType.label
          let l = 0
          if (
            existingAssignments.has(companyId) &&
            existingAssignments.get(companyId).has(vehicleTypeId)
          ) {
            const foundTVG = tripVehicleGroupResponse?.find((tvg) => tvg.tripVehicleGroupId === rv.tripVehicleGroupId)
            for (const assignmentDetails of existingAssignments
              .get(companyId)
              .get(vehicleTypeId).get(foundTVG?.parentTripVehicleGroupId || foundTVG?.tripVehicleGroupId || rv.tripVehicleGroupId)) {
              const das = []
              for (const driverId of assignmentDetails.driverIds) {
                const driverMatches = responseFaction.allDrivers.filter(
                  (d) => d.userId === driverId
                )
                if (driverMatches == null || driverMatches.length == 0) {
                  continue
                }
                const driverName = `${driverMatches[0].firstName} ${driverMatches[0].lastName}`
                const driverNumber = getDriverPhoneNumber(driverMatches[0])

                das.push({
                  driver: {
                    name: driverName,
                    userId: driverId,
                    phone: driverNumber,
                  },
                  tripAssignmentId: null,
                })
              }
              const vehicleMatches = responseFaction.allVehicles.filter(
                (v) => v.vehicleId === assignmentDetails.vehicleId
              )
              if (vehicleMatches == null || vehicleMatches.length == 0) {
                continue
              }
              const vehicleName = `${vehicleMatches[0].vehicleModel} ${vehicleMatches[0].vehicleName}`
              if (!!rv.tripVehicleGroupId) {
                const foundTVG = tripVehicleGroupResponse?.find((tvg) => tvg.tripVehicleGroupId === rv.tripVehicleGroupId)
                if (foundTVG) {
                  const foundModel = model.find(m => m.tripVehicleGroupId === foundTVG.parentTripVehicleGroupId ||
                  m.tripVehicleGroupId === foundTVG.tripVehicleGroupId
                  )
                  if (!foundModel) {
                    model.push({
                      tripVehicleGroupId: foundTVG.parentTripVehicleGroupId,
                      name: foundTVG.name,
                      vehicleAssignments: [
                        {
                          driverAssignments: das,
                          vehicle: {
                            name: vehicleName,
                            vehicleId: assignmentDetails.vehicleId,
                          },
                          vehicleAssignmentId: null,
                          vehicleTypeId: vehicleTypeId,
                          vehicleTypeLabel: vehicleTypeLabel,
                        }
                      ],
                    })
                  } else {
                    foundModel.vehicleAssignments.push({
                      driverAssignments: das,
                      vehicle: {
                        name: vehicleName,
                        vehicleId: assignmentDetails.vehicleId,
                      },
                      vehicleAssignmentId: null,
                      vehicleTypeId: vehicleTypeId,
                      vehicleTypeLabel: vehicleTypeLabel,
                    })
                  }
                }
              } else {
                if (model.length == 0) {
                  model.push({
                    tripVehicleGroupId: null,
                    name: '',
                    vehicleAssignments: [],
                  })
                }
                model[0].vehicleAssignments.push({
                  driverAssignments: das,
                  vehicle: {
                    name: vehicleName,
                    vehicleId: assignmentDetails.vehicleId,
                  },
                  vehicleAssignmentId: null,
                  vehicleTypeId: vehicleTypeId,
                  vehicleTypeLabel: vehicleTypeLabel,
                })
              }
              l++
              driversToAdd -= das.length
            }
          }
          for (var k = l; k < rv.quantity; k++) {
            if (!!rv.tripVehicleGroupId) {
              const foundTVG = tripVehicleGroupResponse?.find((tvg) => tvg.tripVehicleGroupId === rv.tripVehicleGroupId)
                if (foundTVG) {
                  const foundModel = model.find(m => m.tripVehicleGroupId === foundTVG.parentTripVehicleGroupId ||
                  m.tripVehicleGroupId === foundTVG.tripVehicleGroupId)
                  if (!foundModel) {
                    model.push({
                      tripVehicleGroupId: foundTVG.parentTripVehicleGroupId,
                      name : foundTVG.name,
                      vehicleAssignments: [
                        {
                          driverAssignments: [
                            {
                              driver: {
                                name: null,
                                userId: null,
                              },
                              tripAssignmentId: null,
                            },
                          ],
                          vehicle: {
                            name: null,
                            vehicleId: null,
                          },
                          vehicleAssignmentId: null,
                          vehicleTypeId: vehicleTypeId,
                          vehicleTypeLabel: vehicleTypeLabel,
                        }
                      ],
                    })
                  } else {
                    foundModel.vehicleAssignments.push({
                      driverAssignments: [
                        {
                          driver: {
                            name: null,
                            userId: null,
                          },
                          tripAssignmentId: null,
                        },
                      ],
                      vehicle: {
                        name: null,
                        vehicleId: null,
                      },
                      vehicleAssignmentId: null,
                      vehicleTypeId: vehicleTypeId,
                      vehicleTypeLabel: vehicleTypeLabel,
                    })
                  }
                }
            } else {
              if (model.length == 0) {
                model.push({
                  tripVehicleGroupId: null,
                  name: '',
                  vehicleAssignments: [],
                })
              }
              model[0].vehicleAssignments.push({
                driverAssignments: [
                  {
                    driver: {
                      name: null,
                      userId: null,
                    },
                    tripAssignmentId: null,
                  },
                ],
                vehicle: {
                  name: null,
                  vehicleId: null,
                },
                vehicleAssignmentId: null,
                vehicleTypeId: vehicleTypeId,
                vehicleTypeLabel: vehicleTypeLabel,
              })
            }
            driversToAdd--
          }
        }
        if (driversToAdd > 0 && model.length) {
          for (let j = 0; j < driversToAdd; j++) {
            model[0].vehicleAssignments[j % model.length].driverAssignments.push({
              tripAssignmentId: null,
              driver: {
                userId: null,
                name: null,
              },
            })
          }
        }
        responseFaction.allDrivers.forEach((driver) => (driver.name = ``))
        childProps.push({
          reservationIds: reservationIds,
          parentReservationIds: parentReservationIds,
          companyId: companyId,
          companyName: companyName,
          reservation: null,
          drivers: responseFaction.allDrivers,
          vehicles: responseFaction.allVehicles,
          model: model,
        })
      }
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          allInfo: childProps,
          quoteId: quoteId,
          title: `Assign ${quoteId}`,
        },
      })
    },
    async acceptReferrals() {
      let anyNotOffered = false
      const reservationIds = this.selectedRows.map((row) => {
        if (row.item.referralStatus !== 'fully_offered') {
          anyNotOffered = true
        }
        return row.item.reservationId
      })

      if (anyNotOffered) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message:
            'There are selected reservations that are Not Offered. Please select only Offered or Accepted reservations and try again.',
        })
        return
      }

      const payload = {
        parentReservationIds: reservationIds,
      }

      const response = await admin.acceptReferralsEnMasse(payload)

      if (response?.status == 200) {
        this.$store.dispatch('app/showAlert', {
          message: 'Referrals accepted.',
        })
      } else {
        this.$store.dispatch('app/showAlert', {
          message: 'Unable to accept referrals.',
        })
      }

      EventBus.$emit('global-table-view-refresh')
    },
    async rejectReferrals() {
      let anyNotOffered = false
      const reservationIds = this.selectedRows.map((row) => {
        if (row.item.referralStatus === 'not_offered') {
          anyNotOffered = true
        }
        return row.item.reservationId
      })

      if (anyNotOffered) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message:
            'There are selected reservations that are Not Offered. Please select only Offered or Accepted reservations and try again.',
        })
        return
      }

      const payload = {
        parentReservationIds: reservationIds,
      }

      const response = await admin.rejectReferralsEnMasse(payload)

      if (response?.status == 200) {
        this.$store.dispatch('app/showAlert', {
          message: 'Referrals rejected.',
        })
      } else {
        this.$store.dispatch('app/showAlert', {
          message: 'Unable to reject referrals.',
        })
      }

      EventBus.$emit('global-table-view-refresh')
    },
    async handleMultiselectClick(option) {
      if (!this.isDisabled) {
        this.dialogMode = option.mode
        option.action()
        if (
          option.mode === 'accept-referrals' ||
          option.mode === 'reject-referrals'
        ) {
          this.dialog = false
        } else if (option.mode === 'offer-referrals') {
          const component = () =>
            import('./ReservationMultiselectQuickRefer.vue')
          this.$store.dispatch('app/openSidebarDialog', {
            data: {
              reservations: this.selectedRows,
              title: 'Offer Referrals',
            },
            component,
          })
        } else if (option.mode === 'offer-route-referrals') {
          const component = () =>
            import('./ReservationMultiselectRouteQuickRefer.vue')
          const ids = this.selectedRows.map(
            (reservation) => reservation.item.reservationId
          )
          try {
            const routeResults = await this.getRoutesForReservations(ids)
            this.$store.dispatch('app/openSidebarDialog', {
              data: {
                reservations: this.selectedRows,
                routes: routeResults.data.tripVehicleGroups,
                title: 'Offer Route Referrals',
              },
              component,
            })
          } catch (err) {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message:
                err.response?.data?.message ||
                'Cannot offer route referrals for selected reservations.',
            })
          }
        } else if (option.mode === 'clear-assignments') {
          const component = () =>
            import('./ReservationMultiSelectAssignments.vue')
          this.$store.dispatch('app/openSidebarDialog', {
            data: {
              reservations: this.selectedRows,
              clearAssignments: true,
              title: 'Clear Assignments',
            },
            component,
          })
        } else if (option.mode === 'assign-drivers-vehicles') {
          if (this.selectedRows.length === 0) {
            return
          }
        } else {
          this.sidebarDialog = true
        }
      } else {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'At least one reservation must be selected.',
        })
      }
    },
    async copyItineraries() {
      const itineraryStringPromises = this.selectedRows.map((row) =>
        getReservationItineraryAsString(row.item)
      )
      const results = await Promise.all(itineraryStringPromises)
      const itineraries = results.join('\n\n')
      navigator.clipboard.writeText(itineraries)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Itinerary info copied to your clipboard',
      })
    },
    async setManualReferralNeeded(value) {
      const reservationIds = this.selectedRows.map(
        (row) => row.item?.reservationId
      )
      await this.$store.dispatch('reservations/setManualReferralNeeded', {
        reservationIds,
        needsManualReferral: value,
      })
      EventBus.$emit('global-table-view-refresh')
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: !value ? 'MARGE Restarted' : 'Removed from MARGE',
      })
    },
    async markAsEnterprise(value) {
      const reservationIds = this.selectedRows.map(
        (row) => row.item?.reservationId
      )
      await this.$store.dispatch('reservations/markAsEnterprise', {
        reservationIds,
        isEnterprise: value,
      })
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: value ? 'Marked as Enterprise' : 'Marked as Not Enterprise',
      })
    },
    updateInvoiceData() {
      const reservationExternalIds = this.selectedRows.map(
        (row) => row.item?.externalId
      )

      const component = () =>
        import('./InvoiceSentDateSidebar.vue')
      return this.$store.dispatch('app/openSidebarDialog', {
        data: {
          reservationExternalIds,
          title: 'Invoice Sent Date',
        },
        component,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
  .disabled {
    ::v-deep .v-list__tile {
      cursor: default;
      background: rgba($border-gray, 0.5) !important;
    }
  }
}
</style>
